$offWhite: #fcfcfc;
$offGrey: #c8c8c8;
$offBlack: #363131;
$pirateMaroon: #833939;
$swashbucklinMaroon: #672e2e;
$pirateBeige: #a58052;
$swashbucklinBeige: #8a6b45;
$pirateGrey: #9c9891;
$swashbucklinGrey: #86837e;
$main-dark: $offBlack;
$main-contrast: $pirateMaroon;
$secondary-contrast: $pirateBeige;
$tertiary-contrast: $pirateGrey;