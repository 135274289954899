@import "./colors.scss";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    flex-direction: column;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 5px 0px;
    color: $offBlack;
}

ul {
    margin: 0;
    padding: 0;
}

#header {
    height: 5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $offWhite;
}

.navButton {
    display: flex;
    color: $offBlack;
    text-decoration: none;
    height: 100%;
    align-items: center;
    padding: 0px 1vw;
    font-weight: bold;
}

.navButton:hover {
    background-color: $pirateGrey;
}

.navButton:has(img), .navImage {
    height: 100%;
}

#headerButtons {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.navButtonTitle {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 2vh;
    white-space: nowrap;
}

#navLogo {
    align-self: flex-start;
}

#menuImg {
    height: 2.8vh;
}

@media only screen and (orientation: landscape) {
    #menuButton {
        display: none;
    }
    #headerButtons {
        display: flex;
    }
}

@media only screen and (orientation: portrait) {
    #menuButton {
        display: flex;
    }
    #headerButtons {
        display: none;
    }
}

.headerChild {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#heroContainer {
    text-align: left;
    position: relative;
    // padding: 2.8vw;
}

#hero {
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

#heroContainer h1 {
    position: absolute;
    bottom: 2.8vw;
    left: 2.8vw;
    color: $offWhite;
}

.padded {
    padding: 2.8vw;
}

.paddedNoTop {
    padding: 0 2.8vw 2.8vw 2.8vw;
}

.paddedNoVertical {
    padding: 0 2.8vw 0 2.8vw;
}

.paddedVertical {
    padding: 2.8vw 0 2.8vw 0;
}

h1.padded {
    padding: 1vh 2.8vw 0 2.8vw;
}

.toc {
    list-style: none;
    padding-left: 2.8vw;
}

.tocitem, .styledAnchor {
    text-decoration: none;
    font-weight: bold;
    color: $offBlack;
}

.article {
    // padding: 2.8vw;
    padding-top: 1vh;
    text-align: left;
    font-size: 15pt;
}

.meetingImg {
    max-width: 100%;
}

.gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.galleryimg {
    display: flex;
    flex-direction: column;
    width: 20%;
    overflow: hidden;
    object-fit: contain;
}

.featured {
    width: 50%;
}

form { 
    display: flex;
    flex-direction: column;
}

input[type=number] {
    width: 20vw;
}

input[type=file] {
    display: none;
}

input[type=submit] {
    cursor: pointer;
}

input, .fileUploadBox {
    margin-bottom: 1.8vh;
    border: $offBlack 3px solid;
    border-radius: 3px;
    background-color: $offWhite;
    height: 5vh;
    font-size: large;
    vertical-align: middle;
    padding: 0.8vh;
}

.fileUploadBox {
    display: flex;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
    margin-top: 0.8vh;
}

label {
    margin-bottom: 0.8vh;
    // height: 10vh;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

.loading {
    width: 6vw;
    height: width;
}

#passwordContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.8vh;
    justify-content: space-between;
}

.eye {
    align-self: center;
}

#pword {
    margin-bottom: 0;
    width: 93%;
}

canvas, .placeHolder {
    display: none;
}